import { UseQueryOptions, useMutation } from '@tanstack/vue-query'
import { MaybeRef } from 'vue'
import { composeCompareAlphanumeric } from '@/modules/base/utils/array'
import { CompanyTreeFilterParams } from './useCompany.types'
import {
  User,
  UserFilterParams,
  UserMutationData,
  userSchema,
} from './useUsers.types'
import {
  composeTypedQueryWithCompanyId,
  createMutationFn,
  useComposeInvalidation,
} from './utils/factory'
import { Endpoint, useUrl } from './utils/url'

// * export all types
export * from './useUsers.types'

export const useQueryUser = (id: MaybeRef<number | null | undefined>) =>
  composeTypedQueryWithCompanyId([Endpoint.Users, id], userSchema)()

export const useQueryUsers = (
  params: CompanyTreeFilterParams & UserFilterParams = {
    includeCurrentCompany: true,
    includeAncestors: false,
    includeDeleted: false,
  },
  options?: UseQueryOptions<User[], unknown, User[]>
) =>
  composeTypedQueryWithCompanyId(
    [Endpoint.Users, params],
    userSchema.array()
  )({
    ...options,
    select: (users) =>
      users.sort(composeCompareAlphanumeric(({ lastName }) => lastName)),
  })

export const useMutationUserUpdate = () => {
  const { invalidate } = useUsersInvalidation()
  const { createUrlWithCompanyId } = useUrl()

  return useMutation({
    mutationFn: createMutationFn<Partial<UserMutationData>>({
      method: 'PATCH',
      path: (variables) => createUrlWithCompanyId(Endpoint.Users, variables.id),
      body: (user) => ({ user }),
    }),
    onSettled: invalidate,
  })
}

export const useMutationUserDelete = () => {
  const { invalidate } = useUsersInvalidation()
  const { createUrlWithCompanyId } = useUrl()

  return useMutation({
    mutationFn: createMutationFn<{ id: number }>({
      method: 'DELETE',
      path: ({ id }) => createUrlWithCompanyId(Endpoint.Users, id),
    }),
    onSettled: invalidate,
  })
}

export const useMutationUserCreate = () => {
  const { invalidate } = useUsersInvalidation()
  const { createUrlWithCompanyId } = useUrl()

  return useMutation({
    mutationFn: createMutationFn<Partial<UserMutationData>>({
      method: 'POST',
      path: () => createUrlWithCompanyId(Endpoint.Users),
      body: (user) => ({ user }),
    }),
    onSettled: invalidate,
  })
}

const useUsersInvalidation = useComposeInvalidation(({ withCompanyId }) => [
  withCompanyId([Endpoint.Users]),
  withCompanyId([Endpoint.Locations]),
])

export const getUserById = (
  users: User[] | undefined,
  userId: number | null
): User | undefined => {
  return users?.find(({ id }) => id === userId)
}
