import { z } from 'zod'
import { Feature } from './useCompany.types'

export enum RoleName {
  Admin = 'admin',
  Manager = 'manager',
  Viewer = 'viewer',
  Member = 'member',
}

export const roleSchema = z.strictObject({
  id: z.number(),
  companyId: z.number(),
  name: z.nativeEnum(RoleName),
  // NOTE: Not exactly the right type, it's actually a subset of Feature
  module: z.nativeEnum(Feature),
})

export type Role = z.infer<typeof roleSchema>
